// i18next-extract-mark-ns-start technology

import React, { FC } from 'react'
import AngleBottomRed from 'src/images/svg/angle-bottom-red.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

interface ITechnologyAreaHeaderProps {
  title: string
  open: boolean
  onClick: () => void
}

const TechnologyAreaHeader: FC<ITechnologyAreaHeaderProps> = ({
  title,
  open,
  onClick,
}) => {
  const { t } = useTranslation('technology')
  const rotateAnimation = `transition-transform ease-in-out duration-200 ${
    open ? 'transform rotate-180' : ''
  }`
  return (
    <button
      type="button"
      className="flex justify-between items-center cursor-pointer md:cursor-default"
      onClick={onClick}
    >
      <h2 className="uppercase text-red text-xl font-palanquinDark font-semibold text-left h-full">
        <span className="whitespace-pre">{emphasizeAmpersand(title, 1.4)}</span>
      </h2>
      <img
        src={AngleBottomRed}
        alt={open ? t('close') : t('open')}
        className={`md:hidden w-8 ml-5 ${rotateAnimation}`}
      />
    </button>
  )
}

export default TechnologyAreaHeader
