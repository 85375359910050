// i18next-extract-mark-ns-start technology

import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import TechnologyRing from 'src/components/sections/technology/TechnologyRing'

interface IRingTechnology {
  id: string
  name: string
  translations: { description: string }[]
}
export interface ITechnologyAreaProps {
  className?: string
  rings: {
    [ringName: string]: IRingTechnology[]
  }
  openTechnologyId?: string
  onTechnologyClick: (technologyId: string) => void
}

const TechnologyArea: FC<ITechnologyAreaProps> = ({
  className,
  rings,
  openTechnologyId,
  onTechnologyClick,
}) => {
  const { t } = useTranslation('technology')

  const getFirstRingTechnologyIndex = (previousRings: JSX.Element[]) => {
    if (!previousRings.length) {
      return 1
    }
    const lastRing = previousRings[previousRings.length - 1]
    return (
      lastRing.props.firstTechnologyIndex + lastRing.props.technologies.length
    )
  }

  const getTechnologyRingComponent = (
    ringName: string,
    ringTechnologies: IRingTechnology[],
    firstTechnologyIndex: number
  ) => (
    <TechnologyRing
      /* i18next-extract-disable-next-line */
      name={t(ringName)}
      technologies={ringTechnologies}
      firstTechnologyIndex={firstTechnologyIndex}
      openTechnologyId={openTechnologyId}
      className="mb-3 md:mb-4 xl:mb-8"
      onTechnologyClick={onTechnologyClick}
    />
  )

  const getRingComponents = () =>
    Object.entries(rings)
      .filter(([, ringTechnologies]) => ringTechnologies.length)
      .reduce(
        (resultArray, [ringName, ringTechnologies]) => [
          ...resultArray,
          getTechnologyRingComponent(
            ringName,
            ringTechnologies,
            getFirstRingTechnologyIndex(resultArray)
          ),
        ],
        []
      )

  return <div className={className}>{getRingComponents()}</div>
}

TechnologyArea.defaultProps = {
  className: '',
}

export default TechnologyArea
