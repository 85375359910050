import React, { FC } from 'react'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

interface IH3Props {
  firstPart: string
  secondPart: string
  className?: string
}

const H3: FC<IH3Props> = ({ firstPart, secondPart, className }) => (
  <h3
    className={`font-palanquinDark text-2xl md:text-3xl md:leading-30px ${className}`}
  >
    <span className="block text-black">
      {emphasizeAmpersand(firstPart, 1.1)}
    </span>
    <span className="block text-red">
      {emphasizeAmpersand(secondPart, 1.1)}
    </span>
  </h3>
)

H3.defaultProps = {
  className: '',
}

export default H3
