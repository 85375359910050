import React, { FC } from 'react'
import Technology from 'src/components/sections/technology/Technology'

export interface ITechnologyRingProps {
  name: string
  technologies: {
    id: string
    name: string
    translations: { description: string }[]
  }[]
  firstTechnologyIndex: number
  onTechnologyClick: (technologyId: string) => void
  openTechnologyId?: string
  className?: string
}

const TechnologyRing: FC<ITechnologyRingProps> = ({
  name,
  technologies,
  firstTechnologyIndex,
  onTechnologyClick,
  openTechnologyId,
  className,
}) => (
  <div className={className}>
    <h4 className="font-palanquinDark font-semibold text-ultraDarkGray text-lg md:text-2xl mb-3 xl:mb-6">
      {name}
    </h4>
    {technologies.map(({ id, name: title, translations }, index) => (
      <Technology
        key={id}
        index={index + firstTechnologyIndex}
        title={title}
        description={translations[0]?.description}
        open={id === openTechnologyId}
        onClick={() => onTechnologyClick(id)}
      />
    ))}
  </div>
)

TechnologyRing.defaultProps = {
  className: '',
}

export default TechnologyRing
