import React, { FC } from 'react'

export interface ITechnologyProps {
  index: number
  title: string
  description?: string
  open: boolean
  onClick: () => void
}

const Technology: FC<ITechnologyProps> = ({
  index,
  title,
  description,
  open,
  onClick,
}) => {
  const buttonColorClass = open
    ? 'text-white bg-red'
    : 'text-mediumDarkGray hover:text-white hover:bg-red'
  const descriptionHeightClass = open ? 'pt-3' : 'h-0'
  return (
    <div className="mb-1 xl:mb-3">
      <button
        type="button"
        onClick={onClick}
        className={`w-full text-left rounded-lg md:text-lg font-semibold ${buttonColorClass} box-content py-1.5 px-2 -ml-2 cursor-pointer`}
      >
        <span className="mr-5">{index < 10 ? `0${index}` : index}</span>
        {title}
      </button>
      {!!description && (
        // TODO use component for rendering html with standard formats
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className={`text-xs md:text-sm box-border text-ultraDarkGray font-semibold overflow-hidden ${descriptionHeightClass}`}
        />
      )}
    </div>
  )
}

export default Technology
