// i18next-extract-mark-ns-start technology

import * as React from 'react'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import TechnologyArea, {
  ITechnologyAreaProps,
} from 'src/components/sections/technology/TechnologyArea'
import { useState } from 'react'
import TechnologyAreaHeader from 'src/components/sections/technology/TechnologyAreaHeader'
import { isMdScreen } from 'src/helpers/layout'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import MediumPageTitle from 'src/components/ui/title/H3'
import FourHeadedItemsGrid from 'src/components/ui/FourHeadedItemsGrid'

const IndexPage = ({ data }) => {
  const { t } = useTranslation('technology')

  const getEmptyRings = () => ({ adopt: [], trial: [], assess: [], hold: [] })
  const technologyAreas = data.technologyRadar.technology_areas
  const areas: ITechnologyAreaProps = technologyAreas.reduce(
    (accumulated, current) => ({
      ...accumulated,
      [current.id]: getEmptyRings(),
    }),
    {}
  )

  data.technologyRadar.blip.forEach((technology) =>
    areas[technology.technologyArea.id][technology.ring].push(technology)
  )

  const [openTechnologyId, setOpenTechnologyId] = useState(null)
  const [openAreaId, setOpenAreaId] = useState(null)

  const onTechnologyClick = (technologyId: string, areaId: string) => {
    if (technologyId === openTechnologyId) {
      setOpenTechnologyId(null)
    } else {
      setOpenTechnologyId(technologyId)
      setOpenAreaId(areaId)
    }
  }

  const onAreaClick = (areaId: string) => {
    if (isMdScreen()) {
      return
    }
    setOpenTechnologyId(null)
    setOpenAreaId(areaId === openAreaId ? null : areaId)
  }

  const getHeaderKey = (areaId) => `H${areaId}`
  const getBodyKey = (areaId) => `B${areaId}`

  const getHeader = (area) => (
    <TechnologyAreaHeader
      key={getHeaderKey(area.id)}
      title={area.translations[0].title}
      open={area.id === openAreaId}
      onClick={() => onAreaClick(area.id)}
    />
  )

  const getBody = (area) => (
    <TechnologyArea
      key={getBodyKey(area.id)}
      rings={areas[area.id]}
      openTechnologyId={openTechnologyId}
      onTechnologyClick={(technologyId) =>
        onTechnologyClick(technologyId, area.id)
      }
      className={
        openAreaId === area.id
          ? ''
          : 'overflow-hidden md:overflow-visible h-0 md:h-auto'
      }
    />
  )

  return (
    <Layout
      title={t('page-title')}
      metas={[{ name: 'description', content: t('Technology radar') }]}
    >
      <div className="container mt-16 md:mt-28 py-10 md:py-20">
        <MediumPageTitle
          firstPart="me&my friends"
          secondPart={t('technology radar')}
          className="mb-16 xl:mb-18"
        />
        <FourHeadedItemsGrid className="gap-x-12 md:gap-x-24 lg:gap-x-12 gap-y-8 lg:gap-y-12">
          {technologyAreas.reduce(
            (gridContent, area) => [
              ...gridContent,
              getHeader(area),
              getBody(area),
            ],
            []
          )}
        </FourHeadedItemsGrid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    technologyRadar {
      blip(
        filter: {
          public: { _eq: true }
          status: { _eq: "published" }
          technology_area: { id: { _nnull: true } }
        }
      ) {
        id
        name
        ring
        technologyArea: technology_area {
          id
        }
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          description
        }
      }
      technology_areas(sort: "sort") {
        id
        translations(filter: { languages_id: { code: { _eq: $language } } }) {
          title
        }
      }
    }
  }
`

export default IndexPage
